<template>
  <div class="umlDiagram">
    <img :src="fullUmlPath" />
  </div>
</template>

<script>
import CompressByDeflate from "@/mixins/compressByDeflate";
export default {
  name: "WorkFlowPlantuml",
  mixins: [CompressByDeflate],
  props: {
    prefixSrc: {
      type: String,
    },
    umlStr: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      umlPrefixSrc: "http://www.plantuml.com/plantuml/png/",
    };
  },

  computed: {
    fullUmlPath() {
      const base64Str = unescape(encodeURIComponent(this.umlStr));
      const deflate = this.compressByDeflate(); //mixins CompressByDeflate
      if (this.prefixSrc && this.prefixSrc != "") {
        return this.prefixSrc + this.encode64(deflate(base64Str));
      }
      return this.umlPrefixSrc + this.encode64(deflate(base64Str));
    },
  },

  methods: {
    encode64(data) {
      let r = "";
      for (let i = 0; i < data.length; i += 3) {
        if (i + 2 == data.length) {
          r += this.append3bytes(data.charCodeAt(i), data.charCodeAt(i + 1), 0);
        } else if (i + 1 == data.length) {
          r += this.append3bytes(data.charCodeAt(i), 0, 0);
        } else {
          r += this.append3bytes(
            data.charCodeAt(i),
            data.charCodeAt(i + 1),
            data.charCodeAt(i + 2)
          );
        }
      }
      return r;
    },
    append3bytes(b1, b2, b3) {
      let c1 = b1 >> 2;
      let c2 = ((b1 & 0x3) << 4) | (b2 >> 4);
      let c3 = ((b2 & 0xf) << 2) | (b3 >> 6);
      let c4 = b3 & 0x3f;
      let r = "";
      r += this.encode6bit(c1 & 0x3f);
      r += this.encode6bit(c2 & 0x3f);
      r += this.encode6bit(c3 & 0x3f);
      r += this.encode6bit(c4 & 0x3f);
      return r;
    },
    encode6bit(b) {
      if (b < 10) {
        return String.fromCharCode(48 + b);
      }
      b -= 10;
      if (b < 26) {
        return String.fromCharCode(65 + b);
      }
      b -= 26;
      if (b < 26) {
        return String.fromCharCode(97 + b);
      }
      b -= 26;
      if (b == 0) {
        return "-";
      }
      if (b == 1) {
        return "_";
      }
      return "?";
    },
  },
};
</script>

<style lang="scss" scoped>
.umlDiagram {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
