<template>
  <div>
    <ProDocumentBreadcrumbs
      :breadcrumbs="[
        { name: 'menu.WorkFlowDiagram', icon: 'PROSmart-Diagram' },
      ]"
      user-role="procurer"
    />
    <pro-report
      title="Document.SearchWorkFlowDiagram"
      :columns="columns"
      :value="fullWorkFlowList"
      row-key="id"
      auto-search
      selectable
      :loading="loading"
    >
      <template #selectedBody="{ row }">
        <q-card class="q-ml-md">
          <q-card-section>
            <WorkFlowPlantuml :umlStr="row.plantUmlString" />
          </q-card-section>
        </q-card>
      </template>
    </pro-report>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs.vue";
import ProReport from "@/components/PROSmart/Report/ProReport";
import WorkFlowPlantuml from "@/components/PROSmart/Diagram/WorkFlowPlantuml.vue";

export default {
  name: "WorkFlowDiagram",
  components: { ProDocumentBreadcrumbs, ProReport, WorkFlowPlantuml },
  data() {
    return {
      regx: /-->/g,
      regxArray: [/Automated Activity/, /自动化活动/, /自動化活動/],
      columns: [
        {
          name: "workflowCode",
          label: this.getRes("CustMenu.Field.WorkflowCode"),
          field: "workflowCode",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
        },
        {
          name: "workflowName",
          label: this.getRes("CustMenu.Field.WorkflowName"),
          field: "workflowName",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
        },
        {
          name: "activityType",
          label: this.getRes("CustMenu.Field.ActivityType"),
          field: "activityType",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
        },
      ],
      fullWorkFlowList: [],
      searchWorkFlowList: [],
      loading: false,
    };
  },

  mounted() {
    this.initWorkflowPlantuml();
  },

  methods: {
    async initWorkflowPlantuml() {
      this.loading = true;
      const res = await this.$proSmart.admin.GetWorkflowPlantumlStringList(
        this
      );
      this.fullWorkFlowList = res.map((item, index) => ({
        id: index + 1,
        ...item,
        activityType: this.showActivityType(item.plantUmlString),
      }));
      this.loading = false;
    },

    showActivityType(umlString) {
      let flag;
      if (umlString.match(this.regx).length == 3) {
        flag = this.regxArray.some((regx) => umlString.match(regx));
      }
      if (flag) return this.getRes("CustMenu.Field.AutomatedActivity");
      return this.getRes("Form.Section.ApprovalFlow");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  th,
  td {
    vertical-align: middle;
  }
}
</style>
